@font-face {
  font-family: 'SFProText';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/SFProTextRegular.otf') format('opentype');
}

@font-face {
  font-family: 'SFProTextSemibold';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/SFProTextSemibold.otf') format('opentype');
}

html * {
  font-family: 'SFProText';
}

html,
body {
  color: #334547;
}

body {
  height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
  height: 100vh;
}

body {
  font-family: 'SFProText';
  font-size: 48px;
  overflow: hidden;
  overflow-y: scroll;
}

:root {
  --header-height: 56px;
  /* --footer-height: 68px; */
  --footer-height: calc(60px + env(safe-area-inset-bottom, 0px));
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.App > header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: var(--header-height);
}

.App > main {
  /* --contentHeight: 100vh; */
  padding-top: var(--header-height);
  padding-bottom: var(--footer-height);
  min-height: 100%;
  height: 100%;
  /* height: calc(100vh - var(--footer-height) - var(--header-height)); */
  /* height: calc(100vh - env(safe-area-inset-bottom, 0px)); */
  /* height: 100vh; */
  overflow-x: hidden;
}

.App > footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1030;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  height: var(--footer-height);
}

/* .App > main {
    padding-bottom: env(safe-area-inset-bottom, 0px);
  } */

.bet-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #fefefe;
  font-size: 0.8rem !important;
}

.bodyContent {
  position: absolute;
  top: 7.7%;
  bottom: 60px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -15px;
  height: 85%;
  width: 100%;
}

.float-right {
  float: right !important;
}

.float-right-red {
  float: right !important;
  color: red;
  font-weight: bold;
  font-size: 14px;
}

.float-right-green {
  float: right !important;
  color: green;
  font-weight: bold;
  font-size: 14px;
}

.float-right-blue {
  float: right !important;
  color: blue;
  font-weight: bold;
  font-size: 14px;
}

.float-left {
  float: left !important;
}

.float-left-gray {
  float: left !important;
  color: gray;
  font-weight: bold;
  font-size: 12px;
}

.header-top {
  background-color: #171717 !important;
  /* height: 6%; */
}

.header-top .logo {
  color: #fffefe;
  font-size: 18px;
}

.header-top .profile-icon {
  color: #fffefe;
  font-size: 18px;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mt150 {
  margin-top: 150px !important;
}

.subTitle.h6 {
  text-transform: uppercase;
  color: gray;
  float: left;
  font-size: 14px;
}

.subTitleLowerCase {
  color: gray;
  text-align: left;
  font-size: 14px;
}

.footer {
  /* position: fixed;
    bottom: 0;
    width: 100%; */
  /* height: 10%; */
  /* line-height: 10px; */
  background-color: #f5f5f5;
  /* margin-right: 0 !important;
    margin-left: 0 !important;
    z-index: 1; */
}

a {
  text-decoration: none !important;
  color: unset !important;
}

.light-text {
  color: black;
}

.dark-text {
  color: #fffefe;
}

.gray-text {
  color: gray;
}

.registration_banner_wrapper {
  position: relative;
  text-align: center;
  color: white;
}

.registration_banner {
  margin-top: 10px;
  width: 100%;
}

.registration_banner_text {
  position: absolute;
  top: 55%;
  left: 65%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  white-space: nowrap;
  float: right;
}

.top_matches,
.top_categories,
.top_leagues,
.long_term_bets {
  margin-top: 10px !important;
  text-align: left;
}

.top_matches h6,
.top_categories h6,
.top_leagues h6 .popular_sports h6 {
  float: left !important;
  font-size: 18px;
}

.top_matches .league_name {
  color: gray;
  padding-top: 5px;
  padding-bottom: 10px;
}

.top_matches .match_time {
  float: right;
  color: green;
}

.top_matches .teams {
  text-align: left;
  float: left;
  color: gray;
}

.top_matches .time {
  text-align: right;
  float: right;
  color: gray;
  padding-left: 0px;
}

.top_matches .winnerWrapper {
  text-align: left;
  float: left;
  color: gray;
}

.betOddsBox {
  border: 1px solid #cacaca;
  text-align: center;
  padding: 16px 0px 16px;
  display: inline-block;
  width: 100% !important;
  border-radius: 5px;
  color: #171717;
  font-weight: bold;
  box-shadow: 1px 2px 7px 0 rgb(0 0 0 / 25%);
  background: #fff;
  background: -moz-linear-gradient(top, #fff 0, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6 100%);
  background: -webkit-linear-gradient(top, #fff, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6);
  background: linear-gradient(180deg, #fff, #f1f1f1 50%, #e1e1e1 51%, #f6f6f6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f6f6f6", GradientType=0);
}

.betOddsBox-selected {
  /* border: 1px solid #dfdede;
    border-radius: 5px;
    padding: 5px 0px;
    margin: 2px;
    display:inline-block;
    position: relative;
    text-align: right;
    color: black;
    width: 50px;
    text-align: center; */
  border: 1px solid #cacaca;
  text-align: center;
  padding: 20px 0px 16px;
  /* padding: 5px 10px; */
  display: inline-block;
  width: 100% !important;
  border-radius: 5px;
  color: #171717;
  font-weight: bold;
  box-shadow: 1px 2px 7px 0 rgb(0 0 0 / 25%);
  background-color: #ffcc00;
}

.top_matches .teams .black-color {
  color: black;
}

.box-info-wrapper {
  margin-top: -5px;
}

div.scrollmenu,
div.scrollmenuFullWidth {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 10px;
}

div.scrollmenuFullWidth {
  text-align: left;
}

div.scrollmenu::-webkit-scrollbar {
  display: none;
}

.scrollmenuFullWidth.blackBgColor {
  background-color: #000;
}

.itemBlackBgColorWithRadius {
  background-color: #000 !important;
  border-radius: 5px;
  margin-right: 5px;
}

.starIcon svg {
  margin-top: -5px !important;
  color: #171717;
}

div.scrollmenu .card {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  margin-right: 10px;
  width: 100%;
  padding-top: 0px;
  border: 1px solid rgb(218, 212, 212);
  border-radius: 10px;
  box-shadow: 1px 2px 7px 0 rgb(0 0 0 / 25%);
}

div.scrollmenuFullWidth .sport-item {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 5px 11px;
  text-decoration: none;
}

div.scrollmenuFullWidth .sport-item-text {
  font-size: 12px !important;
}

.sport-item-text.activeColor {
  color: white;
}

.solid {
  border-top: 2px solid #999;
  border-color: green;
}

.scrollmenuFullWidth::-webkit-scrollbar {
  display: none;
}

.flag img {
  height: 23px;
}

.sport_list_item .item1 {
  float: left !important;
  padding-top: 2px;
}

.sport_list_item .item2 {
  text-align: left !important;
  padding-left: 13px !important;
  font-size: 17px;
  font-weight: 400;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -0.2px;
}

.sport_list_item .item3 {
  float: right !important;
}

.sport_list_item .item3 .number {
  margin-left: 25px;
  color: gray;
}

.sport_list_item .item3 .right-icon {
  float: right;
  color: gray;
}

.footer-icons {
  margin-top: -6px;
  margin-right: 5px;
}

.sport_time_detail {
  float: left;
  text-align: left;
  width: 100%;
}

.sport_time_detail.title2 {
  margin-top: -5px;
}

.sport_time_detail.title2 h6 {
  font-weight: 700 !important;
}

.login {
  /* padding: 130px 10px 10px 10px; */
  height: 100%;
  background-color: #333333;
  color: white;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
}

.login .top-header-right,
.profile .top-header-right,
.bettinghistory .top-header-right,
.result {
  text-align: right;
  margin-right: 15px;
  margin-top: 10px;
}

.login .top-header-left,
.profile,
.top-header-left,
.bettinghistory,
.result {
  text-align: left;
}

.login .logoWrapper,
.profile .logoWrapper,
.bettinghistory .betWrapper,
.result {
  text-align: center !important;
  margin-top: 7%;
}

.profile .logoWrapper,
.bettinghistory .betWrapper,
.result {
  text-align: center !important;
  margin-top: 5%;
}

.login .logoWrapper .logo,
.profile .logoWrapper .logo,
.bettinghistory .betWrapper .bet,
.result {
  width: 65%;
  margin-bottom: 30px;
}

.input_icon {
  text-align: right;
}

.input_text {
  text-align: left;
  padding-left: 0;
}

.input_text input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #efecec;
  width: 100%;
}

.buttonWrapper button {
  background-color: #b3afaf;
  border: 0;
  padding: 5px 12px;
  border-radius: 5px;
  color: #fff;
}

.matchCard {
  padding: 10px;
  padding-top: 0px;
  padding-left: 0;
  padding-bottom: 0px;
  margin-bottom: 2px;
  border-left: none;
  border-right: none;
  box-shadow: 0px 1px 2px 0 rgb(0 0 0 / 25%);
}

.matchCard .row {
  flex-wrap: nowrap !important;
}


.matchCard .dayTime {
  text-align: start;
  line-height: 21px;
  height: 21px;
  float: left;
  font-size: 12px;
}

.matchCard .dayTime .day {
  padding: 2px 9px;
  background-color: #55627a;
  color: #fff;
}

.matchCard .dayTime .day-bg-will-no-live {
  padding: 2px 9px;
  background-color: black;
  color: #fff;
}

.matchCard .dayTime .day-bg-will-live {
  padding: 2px 9px;
  background-color: #008000;
  color: #fff;
}

.matchCard .dayTime2 .day {
  padding: 2px 5px;
  background-color: gray;
  color: #fff;
}

.matchCard .dayTime .day2 {
  padding: 2px 9px;
  background-color: green;
  color: #fff;
}

.matchCard .dayTime .day2-bg-ht {
  padding: 2px 9px;
  background-color: #1682ce;
  color: #fff;
}

.ptx-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.matchCard .dayTime .time {
  padding: 2px 9px;
  background-color: #000;
  color: #fff;
  min-width: 60px;
  border-radius: 0 9px 9px 0px;
  /* overflow: hidden; */
}

.matchCard .dayTime .time .double-time {
  border-radius: 0 9px 9px 0;
  background-color: var(--bs-gray-500);
  padding: 2px 0.35rem;
  margin-right: -20px;
  margin-left: 0.5rem;
}

.matchCard .dayTime2 .time {
  padding: 2px 5px;
  background-color: #000;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.matchCard .passive {
  background-color: gray !important;
  color: #fff !important;
}

.matchCard .selected {
  font-weight: 700;
}

.notifation_favourite_wrapper {
  padding-right: 0px !important;
}

.matchCard .notification {
  margin-right: 10px;
}

.matchCard .count {
  text-align: right;
}

.matchCard .teams_wrapper .team1 {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.matchCard .teams_wrapper div {
  font-size: 13px;
  color: #000;
  line-height: 1.29;
  letter-spacing: -0.4px;
  font-weight: 700;
  font-family: system-ui;
  height: fit-content;
}

.matchCard .matchCard-score {
  width: 50px;
  display: flex;
  justify-content: flex-end;
  padding-right: 0.25rem;
}

.matchCard .matchCard-score span {
  width: 12px;
}

.matchCard .bet_name {
  padding-left: 20px;
}

.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-trigger {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);*/
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto;
  /* Strictly for positioning */
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.matchCard .favourite svg {
  margin-top: -3px !important;
}

.menu-trigger img {
  height: 24px;
  width: 24px;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  z-index: 9999;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.matchCard .teams_wrapper {
  text-align: left;
  padding-left: 4px;
  padding-bottom: 0px;
  padding-top: 0px;
  font-size: 17px;
  color: #171717;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu.active ul li a:hover {
  border-radius: 8px;
  background-color: #f2f2f2;
}

.matchCard .teams_wrapper .team2 {
  display: flex;
  justify-content: space-between;
}

.betname_bets_wrapper {
  text-align: left;
}

/* .betname_bets_wrapper .betItem {
    margin: 0 !important;
    padding: 5px !important;
    align-self: end;
  }  */

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li a {
  text-decoration: none;
  color: #333333;
  padding: 8px 10px;
  display: block;
  cursor: pointer;
}

.menu li a img {
  height: 24px;
  width: 24px;
}

.longtermbets_match_card {
  text-align: left;
  padding: 10px;
}

.longtermbets_match_card .title {
  font-size: 12px;
}

.longtermbets_match_card .subTitle {
  font-size: 11px;
  color: gray;
}

.longtermbetname_bets_wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
}

.betname_bets_wrapper .bet_name {
  color: #000;
  font-size: 15px;
  /* padding-left: 20px; */
  /* padding-top: 4px; */
  /* padding-top: 5px;
    padding-bottom: 5px; */
}

.betname_bets_wrapper .bets_name {
  color: gray;
  margin: auto;
  font-size: 10px;
}

.betname_bets_wrapper .bets_title {
  color: gray;
  margin: auto;
  font-size: 12px;
}

.betname_bets_wrapper .bets_item {
  color: black;
  margin: auto;
  font-size: 12px;
}

.betname_bets_wrapper .bets_x {
  color: red;
  margin: auto;
  font-size: 16px;
  font-weight: bold;
}

/* .betBoxWrapper {
    border: 1px solid #cacaca;
    text-align: center;
    padding: 5px 10px;
    display: inline-block;
    width: 100% !important;
    border-radius: 5px;
  } */

.betTitleWrapper {
  text-align: center;
}

.matchDetail {
  padding: 5px;
}

.matchDetail .matchDetailBetItem {
  padding: 5px;
  border: 1px solid #cacaca;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 2px #cac5c5;
}

.matchdetail_header {
  font-weight: 600;
}

.matchdetail_header_img {
  width: 100%;
}

.matchdetail_header .team1 {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.matchdetail_header .team2 {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.matchdetail_header .team1Score {
  margin-top: 10px;
}

.matchdetail_header .team2Score {
  margin-top: 10px;
}

.matchdetail_header .score {
  margin-top: 0 px;
  font-size: 10px;
  color: #fff;
  border: 2px solid rgb(31, 141, 192);
  border-radius: 30%;
}

.matchdetail_header .score > span {
  width: 36px;
  height: 36px;
  padding: 7px;
  background: transparent;
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.matchdetail_header .headerMatchInfo {
  text-align: left;
  color: #fff;
  font-size: 11px;
  padding: 0px 25px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.matchdetail_header .headerMatchHalfTimeResult {
  text-align: center;
  color: #cacaca;
  font-size: 11px;
  margin-top: 10px;
  overflow: visible;
  height: 0px;
}

.headerMatchInfo .stats {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

.long-term-single-wrapper {
  border: 1px solid #cacaca;
  padding-bottom: 8px;
  border-radius: 5px;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.p-left-20 {
  padding-left: 20px !important;
}

.p-right-20 {
  padding-right: 20px !important;
}

.bet-type {
  display: inline-flex;
  overflow: auto;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.bet-type-title {
  padding: 5px;
  margin: 0 10px;
  white-space: nowrap;
}

.bet-type::-webkit-scrollbar {
  display: none;
}

.bet-type {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes modalSlide {
  from {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-modal-open {
  display: block;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 20%;
  width: 100%;
  height: 90%;
  background-color: #fff;
  z-index: 666666;
  animation: modalSlide 0.3s ease-out forwards;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.custom-modal-close {
  display: none;
}

.hr-modal {
  margin-top: 50px;
  opacity: 100% !important;
  margin-bottom: 0px !important;
}

.subHeader-modal {
  background: #000;
  height: 60px;
  margin-top: -8px;
  padding-top: 10px;
}

.hr-bet-type {
  opacity: 100% !important;
  margin-bottom: 0px !important;
  background: #000 !important;
  margin-top: 10px;
}

.match-tracker {
}

.match-tracker-header {
  padding: 5px 0 5px 0;
  height: 40px;
  background-color: #ffffff;
}

.svg-size-20 {
  height: 20px;
  width: 20px;
}

.svg-size-8 {
  height: 8px;
  width: 8px;
}

.svg-size-14 {
  height: 14px;
  width: 14px;
}

.svg-size-18 {
  height: 18px;
  width: 18px;
}

.mb12half {
  margin-bottom: 12.5px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-4 {
  margin-right: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.soccer-field {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 320px) {
  .soccer-field {
    height: 260px;
  }

  .sub-tracker-wrapper {
    height: 240px;
  }

  .card-center {
    margin-top: 8%;
  }

  .circle-box {
    height: 24px !important;
    width: 22px !important;
    border-radius: 50%;
    margin: 4px 0 !important;
  }

  .circle-value {
    padding-top: 2px !important;
    font-size: 11px !important;
    font-weight: 400;
  }
}

@media screen and (max-width: 375px) {
  .soccer-field {
    height: 280px;
  }

  .sub-tracker-wrapper {
    height: 280px;
  }

  .card-center {
    margin-top: 8%;
  }

  .circle-box {
    height: 26px !important;
    width: 26px !important;
    border-radius: 50%;
    margin: 12px 0 !important;
  }

  .circle-value {
    padding-top: 4px !important;
    font-size: 12px !important;
    font-weight: 400;
  }
}

@media screen and (min-width: 425px) {
  .soccer-field {
    height: 370px;
  }

  .sub-tracker-wrapper {
    height: 370px;
  }

  .card-center {
    margin-top: 14% !important;
  }

  .circle-box {
    height: 34px !important;
    width: 34px !important;
    border-radius: 50%;
    margin: 20px 0 !important;
  }

  .circle-value {
    padding-top: 6px !important;
    font-size: 14px !important;
    font-weight: 400;
  }
}

a.carousel-control-prev {
  display: none;
}

a.carousel-control-next {
  display: none;
}

.slide {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.match-tracker ol.carousel-indicators {
  top: calc(100% + 15px);
}

.match-tracker ol li {
  background-color: white;
  height: 5px;
  width: 35px;
  display: flex;
  margin-left: 15px;
}

.match-tracker ol li.active {
  background-color: green;
  height: 5px;
  width: 35px;
}

.carousel-item {
  height: 70%;
  background-color: #fff;
}

.card-slide {
  display: flex;
  flex-direction: column;
}

.card-header:first-child {
  border-radius: unset !important;
}

.card-header-wrapper {
  padding: 0.5rem 1rem;
  margin-bottom: 3px;
}

.color-gray {
  color: gray;
}

.counter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-count-wrapper {
  margin-left: 12px;
  padding: 6px;
  margin-top: -12px;
}

.box-count-wrapper > div:nth-of-type(1) {
  background: rgb(18, 170, 18);
  color: white;
  font-size: 24px;
  padding: 2px;
  font-weight: 600;
  min-width: 40px;
}

.box-count-wrapper .time-text {
  font-size: 10px;
}

.card-slide .card-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.warnings-icons {
  margin-top: 1px;
  margin-bottom: 4px;
}

.manager-text {
  font-weight: 700;
}

.manager-wrapper {
  padding: 8px;
  margin-bottom: 6px;
  margin-top: -12px;
}

.hr-manager {
  margin-top: 48px;
}

.logo-norobet {
  width: 40%;
  height: 30px;
  margin-top: -20px;
  margin-bottom: 10px;
}

.stadium-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.card-slide .card-text {
  margin: 12px 0;
}

.bar-wrapper {
  display: flex;
  flex-direction: row;
}

.card-left-bar {
  background: black;
  height: 10px;
}

.card-right-bar {
  background: rgb(58, 181, 58);
  height: 10px;
  margin-left: 2px;
}

.card-left-number {
  margin-top: 10px;
  font-weight: 800;
  font-size: 16px;
  float: left;
}

.card-right-number {
  margin-top: 10px;
  font-weight: 800;
  font-size: 16px;
  color: rgb(58, 181, 58);
  float: right;
}

.goal-titles {
  width: 100%;
  height: 10px;
}

.card-left-text-number {
  color: black;
  font-size: 24px;
  font-weight: 700;
}

.card-middle-text-number {
  color: gray;
  font-size: 18px;
  margin-top: 6px;
}

.card-right-text-number {
  color: rgb(58, 181, 58);
  font-size: 24px;
  font-weight: 700;
}

.card-middle-bar {
  background-color: rgb(225, 218, 218);
  margin: 0 1px 0 3px;
}

.card-left-text {
  color: black;
}

.card-middle-text {
  color: gray;
}

.card-right-text {
  color: rgb(58, 181, 58);
}

.horizontal-bar {
  height: 100px;
  width: 15px;
  background-color: rgb(225, 218, 218);
  border-radius: 2px;
  position: relative;
}

.horizontal-bar-value {
  position: absolute;
  bottom: 0px;
  width: 15px;
  border-radius: 2px;
}

.card-bottom-fixed {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 48px;
}

.boxes-row-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.boxes-column-wrapper {
  display: flex;
  flex-direction: column;
}

.single-position-box {
  color: #fff;
  padding: 2px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 800;
  min-width: 16px;
}

.box-gray-color {
  background-color: #b6b4b4;
}

.box-green-color {
  background-color: rgb(58, 181, 58);
}

.box-black-color {
  background-color: black;
}

.light-grey-text {
  color: #7e7b7b;
}

.fsize-11 {
  font-size: 11px;
}

.fsize-10 {
  font-size: 10px;
}

.fsize-11 {
  font-size: 11px;
}

.fsize-13 {
  font-size: 13px;
}

.fsize-14 {
  font-size: 14px;
}

.fsize-16 {
  font-size: 16px;
}

.fsize-18 {
  font-size: 18px;
}

.fsize-18 {
  font-size: 18px;
}

.fsize-20 {
  font-size: 20px;
}

.fweight {
  font-weight: normal;
}

.fweight-bold {
  font-weight: bold;
}

.box-horizontal-line {
  width: 9px;
  height: 2px;
  margin: 10px 1px 0 1px;
  background-color: rgb(58, 181, 58);
}

.horizontal-bar-line-right {
  position: relative;
  background-color: rgb(58, 181, 58);
  height: 98px;
  width: 1px;
}

.vertical-line {
  height: 1px;
  width: 18px;
  margin: 1px 0 1px -8.5px;
}

.league-line-position-box {
  position: absolute;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 800;
  min-width: 16px;
  margin-left: -7px;
}

.center {
  display: flex;
  justify-content: center;
}

.sub-tracker-wrapper {
  margin: 0 4px;
}

.hr-sub-tracker {
  width: 100%;
}

.sub-tracker-title {
  margin-top: 4px;
  font-weight: 600;
  font-size: 10px;
}

.bottom-select-line {
  background-color: rgb(58, 181, 58);
  width: auto;
  height: 3px;
  margin-top: 4px;
}

.card-center {
  margin-top: 8%;
}

.soccer-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.soccer-card-wrapper > div {
  margin: 3px 10px 0 10px;
}

.caret-wrapper {
  display: flex;
  flex-direction: row;
}

.percent-small {
  font-size: 16px;
}

.black-medium-title {
  color: black;
  font-size: 22px;
  font-weight: 700;
}

.green-medium-title {
  color: rgb(58, 181, 58);
  font-size: 22px;
  font-weight: 700;
}

.header-row-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.green-title {
  color: rgb(58, 181, 58);
}

.form-bar-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 14px;
}

.table-container {
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  overflow: auto;
  max-height: 270px;
  margin: 10px 0;
}

.green {
  color: rgb(27, 125, 216);
}

.hr {
  text-align: right;
  margin-right: 0;
}

.form-bar-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 14px;
}

.table-container div:nth-of-type(1) {
  flex-grow: 1;
}

.table-container div:nth-of-type(2) {
  flex-grow: 5;
}

.table-container div:nth-of-type(3) {
  flex-grow: 1;
}

.table-container div:nth-of-type(4) {
  flex-grow: 1;
}

.table-container div:nth-of-type(5) {
  flex-grow: 1;
}

.table-container div:nth-of-type(6) {
  flex-grow: 1;
}

.table-container div:nth-of-type(7) {
  flex-grow: 1;
}

.table-container div:nth-of-type(8) {
  flex-grow: 1;
}

.table-container div.table-header {
  background: #e2e0e091;
  color: #635d5d;
}

.table-container .box-child {
  font-size: 13px;
  font-weight: 500;
}

.pl-8 {
  padding-left: 8px;
}

.table-container .table-box {
  border-top: 1px solid #e2e0e091;
  border-bottom: 1px solid #e2e0e091;
}

.tb-right {
  border-right: 1px solid #e2e0e091;
}

.tb-left {
  border-left: 1px solid #e2e0e091;
}

.table-container .table-box-line {
  position: absolute;
  height: 17px;
  width: 2px;
  margin: 1px;
}

.table-container .table-team-box {
  display: flex;
  align-self: flex-start;
}

.table-caret {
  position: absolute;
  margin-left: 5px;
  margin-top: 3px;
}

.athlete-name-box {
  display: flex;
  align-self: flex-start;
}

.lineup-match-postion {
  font-weight: 200;
}

.mb2 {
  margin-bottom: 2px;
}

.mt2 {
  margin-top: 2px;
}

.circle-box {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  margin: 6px 0;
}

.circle-value {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.caret-right {
  float: right;
  color: gray;
  margin-right: 5px;
  margin-left: 5px;
}

.login-button {
  background-color: green !important;
  border-color: green !important;
  color: #fff;
  padding: 8px;
  width: 96% !important;
  font-weight: 600;
  font-size: 16px;
  margin-top: 12px;
}

.round {
  border: 1px solid gray;
  border-radius: 90%;
  padding: 1px;
  width: 100px;
  margin-right: 10px;
  text-align: center;
  font-size: 17px;
}

.round.active {
  border-radius: 90%;
  background-color: #ffcc00;
}

.backContainer {
  position: absolute;
  top: 40px;
  left: 15px;
  flex-direction: row;
  z-index: 9;
}

.caret-left {
  float: left;
  color: gray;
  margin-right: 5px;
  margin-left: 5px;
}

.login-button {
  background-color: rgb(58, 181, 58);
  color: #fff;
  padding: 8px;
  width: 96% !important;
  font-weight: 600;
  font-size: 16px;
  margin-top: 12px;
  border-radius: 7px;
}

.bet-slip-segment-button {
  padding: 7px 20px 7px;
  cursor: pointer;
  margin: 10px -6px 2px;
  border-radius: 7px;
  opacity: 0.2;
  background-color: #ffffff;
  color: #333333 !important;
}

.segment-active {
  background-color: #ffffff !important;
  color: #333333 !important;
  opacity: 1 !important;
}

.bet_active {
  background-color: #ffcc00;
}

.bet-slip-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.bet-slip-row-inside-space {
  padding: 14px 0;
}

.bet-textfield:focus,
.bet-textfield:active {
  outline-width: 0;
}

.bet-textfield {
  margin: 4px;
  padding: 4px;
  width: 100px;
}

.minus-plus-button {
  padding: 5px 18px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 1px;
  background-color: #ffffff;
  color: #171717 !important;
}

.minus-button-style {
  margin: 15px 0px 1px 1px;
}

*::selection {
  -webkit-appearance: none;
  outline: none;
}

.flex {
  display: flex;
}

.numpad-container {
  z-index: 999999;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #635d5d;
}

.numpad-button {
  height: 47px;
  padding-top: 8px !important;
  border: 1px solid #000;
  color: white !important;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  background-color: #50585a;
}

.background-soft {
  background-color: #333333;
}

a.preset {
  padding: 13px;
  margin: 5px;
  border-radius: 5px;
  color: #ffff !important;
  cursor: pointer;
}

.numpad-selected-values-container {
  border-bottom: 1px solid #000;
  background-color: #333333 !important;
  padding: 12px;
}

.numpad-selected-value {
  color: white !important;
  font-size: 24px;
  font-weight: 600;
  text-align: end;
}

.numpad-unit-value {
  color: #a1a1a1;
  font-size: 20px;
  text-align: start;
}

.mr-neg-24 {
  margin-right: 24px !important;
}

.text-align-start {
  text-align: start;
}

.text-align-end {
  text-align: end;
}

.banker-bg {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  align-items: center;
}

.banker-text {
  color: #ffff;
  font-weight: bold;
  font-size: 12;
  margin: 0;
}

.combination-scrollView {
  max-height: 150px;
  float: left;
  overflow-y: scroll;
  padding: 8px 0 8px;
  margin-top: 5px;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.hr {
  text-align: right;
  margin-right: 0;
}

.placeBet-button {
  background-color: green !important;
  border-color: green !important;
  color: #fff !important;
  padding: 8px;
  width: 96% !important;
  font-weight: 600;
  font-size: 16px;
  margin-top: 12px;
  border-radius: 7px;
}

.placeBet-button.btn-danger {
  background-color: var(--bs-danger) !important;
}

.profile .customer-info {
  text-align: center;
  padding: 5px;
}

.profile {
  font-size: 15px;
}

.hrtext {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hrtext p {
  font-size: 16px;
  flex-grow: 0;
}

.headerhr {
  flex-grow: 1;
  height: 2px;
  margin: 0 0 0 0.8rem;
  background-color: #9f9f9f;
}

.fade-text-gray {
  color: gray;
  opacity: 0.5;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-10 {
  font-size: 11px;
}

.m-0 {
  margin: 0px;
}

.color-blue {
  color: rgb(21, 161, 226);
}

.decrement-button {
  color: black;
  border-radius: 50px;
  width: 40%;
  height: 95%;
}

.increment-button {
  color: black;
  border-radius: 50px;
  margin-left: 20px;
  width: 40%;
  height: 95%;
}

.stake-button {
  color: black;
  border-radius: 50px;
  width: 40%;
  height: 95%;
}

.preset {
  width: 44%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.font-size-12 {
  font-size: 12px;
}

.upload-document-border {
  border: 1px solid rgba(90, 87, 87, 0.842);
  border-radius: 5px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-2 {
  margin-right: 2px;
}

.text-align-left {
  text-align: left;
}

.visible-file-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.m-0 {
  margin: 0;
}

select#live_match_sorting {
  border: 0px;
  outline: 0px;
  color: gr;
}

.place-bet-modal {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  z-index: 9999999;
  background-color: #fff;
  margin: 60px 20px;
  transform: translate(0, -50%);
}

.place-bet-modal-inner {
  margin: 20px 0 20px 0;
}

.place-bet-modal-close {
  display: none;
}

.modal-button-success {
  background-color: rgb(58, 181, 58);
  color: #fff !important;
  padding: 6px;
  width: 80% !important;
  font-weight: 600;
  font-size: 16px;
  margin-top: 8px;
  border-radius: 7px;
  margin: 22px;
}

span.modal-span {
  margin-bottom: 40px;
  font-size: 16px;
}

.modal-button-error {
  background-color: rgb(181, 58, 58);
  color: #fff !important;
  padding: 6px;
  width: 80% !important;
  font-weight: 600;
  font-size: 16px;
  margin-top: 8px;
  border-radius: 7px;
  margin: 22px;
}

.react-datepicker__month-container {
  width: 320px;
}

.green-underline {
  border-bottom: 3px solid rgb(58, 181, 58);
}

/* .bets {
    padding-right: 10px;
  } */

.tip-types {
  width: 100%;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  min-height: 40px;
  transition: all 0.5s;
  -webkit-transition: transform 0.5s;
  -webkit-transition: -webkit-transform 0.5s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 0 0.25rem;
}

.tip-types .tip-type {
  display: inline-block;
  width: 16%;
  text-align: center;
  font-size: 90%;
}

.tip-types .tip-type a {
  padding: 5px;
  border: 1px solid #cacaca;
  /* margin-right: 7.2px; */
  display: inline-block;
  border-radius: 5px;
  /* width: 95%; */
  overflow: hidden;
  display: block;
}

.tip-types .tip-type .selected {
  font-weight: 700;
  background: green;
  color: white;
}

.market-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  border: 1px solid rgb(218, 212, 212);
  padding: 5px 10px 10px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 2px 7px 0 rgb(0 0 0 / 25%);
}

.league-names {
  padding-top: 29px;
  font-size: 11px;
  text-align: left;
  font-weight: 700;
  background-color: gainsboro;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* .active { 
    color:#3b69c8 !important;
  } */
svg {
  fill: currentcolor;
}

.footer .active img {
  filter: invert(50%) sepia(81%) saturate(5892%) hue-rotate(120deg) brightness(100%) contrast(64%);
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.footer-slot .active {
  color: #171717 !important;
}

.footer-slot span {
  display: block;
  padding: 0;
  font-size: 15px;
}

.footer-slot span:nth-child(2) {
  display: block;
  padding-top: 6px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ms {
  margin-left: -1cm !important;
}

.bet_name_center {
  color: #000;
  padding-top: 2px;
  padding-bottom: 10px;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.footer-circle-item {
  height: 68px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  /* position: absolute;
      width: 20%;
      left: 50%;
      transform: translateX(-50%);
      height: 70px; */
}

.circle-overflow {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
}

.circle {
  border-radius: 50%;
  background-color: #ffcc00;
  font-size: 22px;
  font-weight: 700;
  display: grid;
  place-content: center;
  height: 58px;
  width: 58px;
}

.circle-amount {
  margin-top: auto;
  margin-bottom: 0.5rem;
  font-size: 15px;
  color: #171717;
  max-width: 100px;
}

.sport-title {
  margin-top: 5px;
}

.selected-background {
  background-color: #ffcc00;
}

.bet_name {
  text-align: left;
  font-size: 17px;
  padding-left: 5px;
}

.my-bets-odd-value {
  font-size: 14px;
  color: #3c3c3c;
  font-weight: bold;
}

.my-bets-icon {
  padding-right: 5px;
  padding-bottom: 5px;
  display: inline-flex;
}

.pl-5 {
  padding-left: 5px;
}

.top-card {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%), 1px 2px 7px 0 rgb(0 0 0 / 12%);
  padding: 5px;
  margin-bottom: 0.75rem;
}

.my-bets-bottom-header {
  font-size: 12px;
  color: #808080;
  letter-spacing: -0.3px;
  font-weight: normal;
}

.my-bets-bottom-data {
  color: #3c3c3c;
  font-size: 14px;
}

.padding-5 {
  padding: 5px;
}

.ml-47 {
  margin-left: 47%;
}

.owl-theme .owl-dots .owl-dot.green.active span {
  background: green;
}

.owl-theme .owl-dots .owl-dot.red.active span {
  background: red;
}

.owl-theme .owl-dots .owl-dot.blue.active span {
  background: #3b69c8;
}

.transaction-won {
  color: green;
}

.transaction-place-bet {
  color: red;
}

.transaction-cashout {
  color: #3b69c8;
}

.transaction-deposit {
  color: chartreuse;
}

.transaction-withdrawal {
  color: #ff0000;
}

.odd-decreased {
  position: relative;
}

.odd-decreased:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 0 0 10px 10px;
  border-style: solid;
  border-color: #ff3b30 transparent;
}

.odd-increased {
  position: relative;
}

.odd-increased:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-width: 10px 0 0 10px;
  border-style: solid;
  border-color: #2db343 transparent;
}

.odd-decreased-slip {
  position: relative;
}

.odd-decreased-slip:before {
  content: '';
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-width: 0 0 10px 10px;
  border-style: solid;
  border-color: #ff3b30 transparent;
}

.odd-increased-slip {
  position: relative;
}

.odd-increased-slip:before {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  border-width: 10px 0 0 10px;
  border-style: solid;
  border-color: #2db343 transparent;
}

.serving-ball {
  max-width: 10px;
}
.transaction-pagination {
  position: fixed;
  right: 0;
  bottom: 1;
  left: 0;
  z-index: 1050;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  height: var(--footer-height);
}

.slot-size {
  width: 100%;
  height: calc(100% - var(--header-height));
  top: var(--header-height);
  bottom: 0;
  left: 0;
}

.button {
  border: 0;
}

.score {
  color: green;
}

.pagination-footer {
  font-size: 20px;
}

.pagination-info {
  font-size: 14px;
  margin-top: 18px;
  margin-left: 15px;
}

/*Seach Input clear icon cursor change*/
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.forza-button-color {
  background-color: #171717;
}

.icon-color {
  filter: invert(1);
}

.active-icon-color {
  filter: invert(50%) sepia(81%) saturate(5892%) hue-rotate(120deg) brightness(100%) contrast(64%);
}

.black-box-icon-color {
  filter: invert(0);
}

.svg-color {
  filter: invert(50%) sepia(81%) saturate(5892%) hue-rotate(120deg) brightness(100%) contrast(64%);
}

.light-black-svg {
  -webkit-filter: invert(0.2); /* Safari and Chrome */
  filter: invert(0.2);
}

.bettingHistoryButton {
  background-color: #171717;
  color: #fff;
  border: 0;
  border-radius: 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem;
}

.match .teams .team {
  width: 100%;
  white-space: nowrap;
  font-size: 0.875em;
  font-weight: bold;
}

.match .teams .team .team-name {
  float: left;
  width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.match .teams .team .wrapper {
}

.match .teams .team .wrapper .score {
  float: right;
  width: 20px;
  font-weight: bold;
}

.match .teams .team .wrapper .handicap {
  float: right;
  width: 10px;
  color: #fff;
  background: #25851f;
  /*border: 1px solid #cea500;*/
  font-size: 0.875em;
  text-align: center;
}

.match .teams .time {
  font-size: 10px;
  color: #777;
  margin-left: -10px;
}

.match .teams .time > .live-time {
  background-color: #333;
  color: #fff;
  padding: 2px 12px 2px 8px;
  margin-left: -5px;
  border-radius: 0px 8px 8px 0px;
}

.match .teams .time > .not-will-live {
  background-color: #444;
  color: #fff;
  padding: 2px 12px 2px 8px;
  border-radius: 0px 8px 8px 0px;
}

.league-selected {
  color: #25851f !important;
}

.yellow-color {
  color: #ffcc00 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.bottom-10 {
  bottom: 10px !important;
  position: relative;
}

.bottom-negative-075 {
  bottom: -0.75px !important;
  position: relative;
}

.text-center {
  text-align: center !important;
}

.ml-05 {
  margin-left: -0.5rem !important;
}

.infinity-scroll {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-y: scroll;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.fs-8 {
  font-size: .85rem !important;
}

.py-1-1 {
  padding-top: 1.025rem !important;
  padding-bottom: 1.025rem !important;
}

.lock-py {
  padding-top: 1.025rem !important;
  padding-bottom: 1.274rem !important;
}

.lock-py-2 {
  padding-top: 1.025rem !important;
  padding-bottom: 1.425rem !important;
}

.score-card {
  display: grid;
  white-space: nowrap;
  color: #008000;
}

.leagues {
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  width: 100%;
  z-index: 1;
  transition: all .5s;
  -webkit-transition: transform .5s;
  -webkit-transition: -webkit-transform .5s;
  display: flex;
}

.leagues .league {
  color: #c1c1c1;
  height: 100%;
  display: block;
  font-size: 12px;
  margin-right:1.2rem;
  flex-shrink: 0;
}

.row > .d-flex {
  margin-top: 0px !important;
}

.gutter-0-5 {
  --bs-gutter-y: -0.8rem;
}

.navbar > .container {
  margin-left:0px !important;
  margin-right:0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bets {
  white-space: nowrap;
  padding-bottom: 0.3rem;
}

.text-align-justify {
  text-align: justify !important;
}

.fs-16 {
  font-size: 16px !important;
}

@media screen and (max-width: 309px) {
  .tenis-fs {
    font-size:10px !important;
  }
}

.border-radius-0 {
  border-radius: 0px !important;
}